
















































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import { Appointment } from "@/types";
import appointmentStoreModule from "@/store/modules/appointment";
import RescheduleAppointmentForm from "@/components/client/RecheduleAppointmentForm.vue";
import appointment from "@/store/modules/appointment";

const { mapActions: apptActions } = createNamespacedHelpers(
  "CLIENT_APPOINTMENT_FORM"
);

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend({
  //<any, any, any, any>
  name: "ClientAppointmentForm",
  components: { RescheduleAppointmentForm },
  props: {
    appointmentId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    apiUrl: `${VUE_APP_API_URL}/v1/file`,
    valid: false,
    showActionDialog: false,
    status: "",
    action: "",
    rescheduleDialog: false,
    year: new Date().getFullYear(),
  }),
  computed: {
    appointment(): Appointment {
      return this.$store.getters["CLIENT_APPOINTMENT_FORM/getAppointment"](
        this.appointmentId
      );
    },
  },
  created() {
    this.fetchAppointment(`?appointmentId=${this.appointmentId}`);
  },
  methods: {
    ...apptActions(["fetchAppointment", "clientUpdateAppointment"]),
    reschedule(payload: { startDate: string }) {
      if (!payload) {
        this.rescheduleDialog = false;
        return;
      }
      this.clientUpdateAppointment({
        payload,
        id: this.appointmentId,
      }).then((data) => {
        if (data) {
          this.fetchAppointment(`?appointmentId=${this.appointmentId}`);
          this.rescheduleDialog = false;
        }
      });
    },
    doAction() {
      let status;

      if (this.action === "Confirm") {
        status = "confirmed";
      } else if (this.action === "Cancel") {
        status = "cancelled";
      }
      this.clientUpdateAppointment({
        payload: { status },
        id: this.appointmentId,
      }).then((data) => {
        if (data) {
          this.fetchAppointment(`?appointmentId=${this.appointmentId}`);
          this.showActionDialog = false;
        }
      });
      // code
    },
    triggerAction(action: string) {
      this.action = action;
      this.showActionDialog = true;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_APPOINTMENT_FORM")) {
      this.$store.registerModule(
        "CLIENT_APPOINTMENT_FORM",
        appointmentStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_APPOINTMENT_FORM");
  },
});
