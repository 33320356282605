






































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";

// import { RosterEntry } from "@/types";
import rosterEntryStoreModule from "@/store/modules/rosterEntry";
import { DATE_REGEX } from "@/util/constants";

const { mapActions: rosterActions } =
  createNamespacedHelpers("CLIENT_ROSTER_FORM");

const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";
export default Vue.extend({
  //<any, any, any, any>
  name: "RescheduleAppointmentForm",
  data: () => ({
    dateRules: [
      (v: string) => !!v || "Date is required",
      (v: string) => DATE_REGEX.test(v) || "Date must be valid format",
    ],
    slots: [] as { label: string; date: string }[],
    slotRules: [(v: string) => !!v || "Time is required"],
    date: "",
    dateFormatted: "",
    dateMenu: false,
    startDate: "",
    valid: false,
  }),
  props: {
    appointment: {
      type: Object,
      required: true,
    },
  },
  watch: {
    dateFormatted: "fetchData",
  },
  methods: {
    ...rosterActions(["fetchEmployeeSlots"]),
    validateForm() {
      const valid = (
        this.$refs.form as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const payload = {
        startDate: this.startDate,
      };

      this.$emit("saved", payload);
    },
    fetchData() {
      if (!this.dateFormatted) return;

      let date: any = this.dateFormatted;

      date = (date as string).split("/");

      const _date = new Date(+date[2], +date[1] - 1, +date[0]).toISOString();
      const params = `?employeeId=${this.appointment.employee._id}&date=${_date}`;
      this.fetchEmployeeSlots(params).then((r) => {
        if (r) {
          this.resetForm(r);
        }
      });
    },
    resetForm(slots: string[]) {
      const roster: { label: string; date: string }[] = [];
      for (let i = 0; i < slots.length; i++) {
        const slot = slots[i];
        const label = moment(slot).tz(tz).format("H:mm A");

        roster.push({
          label,
          date: slot,
        });
      }

      this.slots = roster;
      (
        this.$refs.rosterForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    formatDate(date: string) {
      if (!date) return "";

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
    parseDate(date: string) {
      if (!date) return null;
      let d: string | string[] = date.split("/");

      d = `${d[2]}-${d[1]}-${d[0]}`;

      return moment(d).tz(tz).format("YYYY-MM-DD");
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_ROSTER_FORM")) {
      this.$store.registerModule("CLIENT_ROSTER_FORM", rosterEntryStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_ROSTER_FORM");
  },
});
